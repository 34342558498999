import { CopyProcessTableRow, PaginatedRow } from '../types';

export const formatApiCall = (data: PaginatedRow[] = []): CopyProcessTableRow[] => {
	return data?.map((e) => {
		return {
			id: e?.programId,
			title: e?.title,
			department: e?.department,
			status: e?.status,
			app_process_is_ready_to_publish: e?.app_process_is_ready_to_publish,
			hierarchy: [e?.programId]
		};
	});
};
