import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import sideNavReducer from '../components/SideNav/slice';
import loginReducer from '../pages/Login/slice';
import formReducer from '../components/FormBuilderLibrary/slice';
import globalProgramSliceReducer from '../components/BaseLayout/slice';
import { GPM } from '../services/gpmAPI';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['globalProgramVariables']
};
const SessionPersistConfig = {
	key: 'sideNav',
	storage: storageSession,
	whilelist: ['sideNav']
}; // This is to session storage

const persistedSideNavReducer = persistReducer(SessionPersistConfig, sideNavReducer);

const reducers = combineReducers({
	globalProgramVariables: globalProgramSliceReducer,
	sideNav: persistedSideNavReducer,
	login: loginReducer,
	form: formReducer,
	[GPM.reducerPath]: GPM.reducer
});

export const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(GPM.middleware)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
