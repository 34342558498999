import { useState } from 'react';

import StyledWrapper from '../../pages/IndividualProgram/style';
import { checkOverflow } from '../../utils/utilFunctions';
import { formatTimestamp } from '../../utils/dateUtils';

import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import { getTemplatesIcon } from './utils';

// openTooltip, setOpenTooltip
export const TemplateColumns = (columnWidths, type) => {
	const [openTooltip, setOpenTooltip] = useState(0);

	const columns = [
		{
			field: 'appId',
			headerName: 'Template ID',
			description: 'Template ID',
			renderCell: (cellValues) => {
				return (
					<>
						<BasicCellText
							isLink
							cellValues={cellValues}
							emptyCell
							openTooltip={openTooltip}
							setOpenTooltip={setOpenTooltip}
							StartIcon={getTemplatesIcon(type)}
							is_ID={true}
						/>
					</>
				);
			},

			flex: columnWidths.appId || 1,
			resizable: true
		},
		{
			field: 'title',
			headerName: 'Template Title',
			description: 'Template Title',
			renderCell: (cellValues) => {
				return <>{cellValues?.formattedValue}</>;
			},
			flex: !columnWidths.title && 1
		},
		{
			field: 'description',
			headerName: 'Description',
			description: 'Description',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						isLink
						cellValues={cellValues}
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
					/>
				);
			},

			flex: columnWidths.description || 1,
			resizable: true
		},
		{
			field: 'published',
			headerName: 'Published',
			description: 'Published',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
						isDate
					/>
				);
			},

			flex: columnWidths.published || 1,
			minWidth: 100,
			resizable: true
		},
		{
			field: 'updated',
			headerName: 'Updated',
			description: 'Updated',
			renderCell: (cellValues) => {
				return (
					<BasicCellText
						openTooltip={openTooltip}
						setOpenTooltip={setOpenTooltip}
						cellValues={cellValues}
						isDate
					/>
				);
			},

			flex: columnWidths.updated || 1,
			minWidth: 100,
			resizable: true
		}
	];

	return columns;
};

export const groupingColDef = (columnWidths, handleRowSelection, stateRef) => {
	let currentTemplate = stateRef?.current?.id;
	return {
		hideDescendantCount: true,
		field: 'submitter',
		headerName: 'Submitter',
		description: 'Submitter',
		valueFormatter: (cellValues) => {
			const row = cellValues?.api?.getRow(cellValues?.id);
			const currentCell = {
				formattedValue: row?.submitter,
				field: 'type',
				id: cellValues?.id
			};
			return (
				<div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
					<Radio
						checked={currentTemplate === cellValues?.id}
						value={cellValues.id}
						onChange={() => {
							currentTemplate = cellValues?.id;
							handleRowSelection(cellValues);
						}}
						name="radio-buttons"
					/>
					<BasicCellText isActive emptyCell cellValues={currentCell} />
				</div>
			);
		},
		flex: !columnWidths.__tree_data_group__ && 1,
		resizable: true
	};
};

export const BasicCellText = ({
	cellValues,
	openTooltip,
	setOpenTooltip,
	StartIcon,
	isLink,
	isDate,
	isActive,
	emptyCell,
	isBlueprint,
	isDisabled,
	is_ID
}) => {
	const formattedText =
		isDate && cellValues?.formattedValue !== '--'
			? formatTimestamp(cellValues?.formattedValue)
			: cellValues?.formattedValue;

	return (
		<Tooltip
			open={openTooltip === `${cellValues?.field}-${cellValues?.id}`}
			onOpen={(e) => checkOverflow(e) && setOpenTooltip(`${cellValues?.field}-${cellValues?.id}`)}
			onClose={() => setOpenTooltip(0)}
			arrow
			placement="top"
			title={cellValues?.formattedValue ? formattedText : 'n/a'}
		>
			<StyledWrapper.CellContainer
				data-testid={`cellContainer-${cellValues?.field}-${cellValues?.id}`}
			>
				{cellValues?.formattedValue && StartIcon && (
					<StartIcon isCurrent isActive={isActive} isBlueprint={isBlueprint} />
				)}
				{!isLink ? (
					<StyledWrapper.CardRowInfo
						width={'100%'}
						status={isDisabled}
						data-testid={`rowInfo-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue
							? is_ID
								? `[${formattedText}]`
								: formattedText
							: emptyCell
							? ''
							: ''}
					</StyledWrapper.CardRowInfo>
				) : (
					<StyledWrapper.CardRowInfoLink
						width={'100%'}
						status={isDisabled}
						data-testid={`rowLink-${cellValues?.field}-${cellValues?.id}`}
					>
						{cellValues?.formattedValue
							? is_ID
								? `[${formattedText}]`
								: formattedText
							: emptyCell
							? ''
							: ''}
					</StyledWrapper.CardRowInfoLink>
				)}
			</StyledWrapper.CellContainer>
		</Tooltip>
	);
};
