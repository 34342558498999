import ProgramIcon from '../../assets/customIcons/programIcon';
import AppProcessIcon from '../../assets/customIcons/applicantProcess';
import AssignedFormIcon from '../../assets/customIcons/assignedForm';
import SubmissionStageIcon from '../../assets/customIcons/submissionStageIcon';
import ReviewStageIcon from '../../assets/customIcons/reviewStageIcon';
import AppFormIcon from '../../assets/customIcons/appFormIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewFormIcon';
import ReviewForm from '../../assets/customIcons/reviewForm';
export const TableIcon = ({ entityType, isBlueprint, isArchived, status, isTemplate }) => {
	switch (entityType) {
		case 'Program':
			return <ProgramIcon width={'10px'} status={status} />;
		case 'Application Instance':
		case 'APPLICATION PROCESS':
		case 'Application Process':
			return (
				<AppProcessIcon
					isBlueprint={isBlueprint}
					isTemplate={isTemplate}
					isArchived={isArchived}
					status={status}
				/>
			);
		case 'Review Form':
			return (
				<AssignedFormIcon
					isBlueprint={isBlueprint}
					isTemplate={isTemplate}
					isArchived={isArchived}
					status={status}
				/>
			);
		case 'Review Form(s)':
			return <ReviewForm status={status} />;
		case 'Review Content':
			return <ReviewFormIcon status={status} isTemplate={isTemplate} isArchived={isArchived} />;
		case 'Submission Stage':
			return (
				<SubmissionStageIcon
					isBlueprint={isBlueprint}
					isTemplate={isTemplate}
					isArchived={isArchived}
					status={status}
				/>
			);
		case 'Evaluation Stage':
			return (
				<ReviewStageIcon
					isBlueprint={isBlueprint}
					isTemplate={isTemplate}
					isArchived={isArchived}
					status={status}
				/>
			);
		case 'Application Form':
			return (
				<AppFormIcon
					isBlueprint={isBlueprint}
					isTemplate={isTemplate}
					isArchived={isArchived}
					status={status}
				/>
			);
		default:
			return null;
	}
};
