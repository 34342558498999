import styled from 'styled-components';
import Button from '@mui/material/Button';

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	& .MuiDataGrid-columnSeparator {
		visibility: ${(props) => props?.hideSecondResizeIcon && 'hidden'};
	}

	& .MuiDataGrid-columnSeparator--resizable {
		visibility: visible !important ;
	}

	& .MuiDataGrid-treeDataGroupingCellToggle {
		visibility: hidden;
	}
`;

const FormHeader = styled.div`
	display: flex;
	margin: ${(props) => props?.margin || '0px 0px 30px 0px'};
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 20px;
	margin-bottom: 30px;
	& .MuiOutlinedInput-notchedOutline,
	.MuiFormLabel-root {
		border-color: ${(props) => !props?.readOnly && ' #ff9800 !important'};
		color: ${(props) => !props?.readOnly && ' #ff9800 !important'};
	}
`;

const LastEdited = styled.h6`
	margin: 0px;
	margin-bottom: ${(props) => props?.marginBottom};
`;

const LastEditedActions = styled.div`
	display: flex;
	gap: 5px;
	margin-right: 20px;
	& button {
		width: fit-content;
		min-width: fit-content;
	}
`;

const CardRowInfo = styled.h6`
	width: ${(props) => props?.width || '55%'};
	text-align: ${(props) => props?.align || 'start'};
	color: black;
	text-overflow: ellipsis;
	max-width: 33ch;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
	gap: 10px;
	margin: ${(props) => props?.rowHovered && '0px'};
`;

const AlreadyEditingText = styled.h6`
	color: #ed6c02;
	display: inline-flex;
	margin-bottom: 0px;
`;

// MODAL
const Title = styled.h4`
	width: 100%;
	text-align: center;
	margin-bottom: 30px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
`;

const ReadyToPublishButton = styled(Button)`
	display: ${({ isReadyToPublish, status }) =>
		isReadyToPublish || status === 'Published' ? 'none' : 'inherit'};
`;

const Description = styled.p`
	text-align: ${(props) => props?.center && 'center'};
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 25px;
	margin-top: ${(props) => props?.marginTop && '40px'};
	padding-top: 75px;
`;

export default {
	FormContainer,
	FormHeader,
	LastEdited,
	LastEditedActions,
	CardRowInfo,
	AlreadyEditingText,
	Title,
	Description,
	ButtonContainer,
	ReadyToPublishButton
};
