import styled from 'styled-components';

const CustomText = styled.h6`
	color: ${(props) => (props?.warningText ? 'rgba(237, 108, 2, 0.8)' : '#013654')};
	font-weight: ${(props) => (props?.bold ? '600' : 'normal')} !important;
	margin: 0px !important;

	& ul li {
		color: ${(props) => (props?.warningText ? 'rgba(237, 108, 2, 0.8)' : '#013654')};
		margin: 0px !important;
	}
`;

export default {
	CustomText
};
