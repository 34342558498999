import { useState, useEffect, useRef, useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';
import StyledWrapper from './style';
import { TableIcon } from '../TableIcon/TableIcon';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const TableTitle = ({ titleData, handleCardClick, handleExpand, style, tooltipText }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const cardRowTitleRef = useRef(null);
	const { displayId, title, status, entityType } = titleData;
	const isArchived = titleData.status == 'Archived' || titleData.isArchived;
	const Icon = titleData.expanded ? ExpandMoreIcon : KeyboardArrowRightIcon;
	const message =
		entityType == 'Application Process'
			? 'stage'
			: entityType == 'Submission Stage'
			? 'application form'
			: 'review form';
	useEffect(() => {
		if (cardRowTitleRef.current) {
			setIsOverflowing(cardRowTitleRef.current.scrollWidth > cardRowTitleRef.current.clientWidth);
		}
	}, [titleData]);

	const tooltipTitle = useMemo(() => {
		if (isOverflowing) {
			return '';
		}

		return tooltipText || `${title}`;
	}, [isOverflowing, title, tooltipText]);

	return (
		<>
			<StyledWrapper.CardRowContainer style={style} width={'100%'} isClickable={!!handleCardClick}>
				{(titleData.hasChildren || titleData.isParent) && (
					<Tooltip
						title={titleData.hasChildren ? '' : `There are no ${message} templates added`}
						arrow
						placement="top"
					>
						<div>
							<IconButton
								sx={{ width: '23px', height: '23px' }}
								disabled={!titleData.hasChildren}
								onClick={() => {
									handleExpand(titleData.row_id, titleData.expanded);
								}}
								aria-label="Example"
							>
								<Icon fontSize="small" sx={{ width: '20px', height: '20px' }}></Icon>
							</IconButton>
						</div>
					</Tooltip>
				)}
				{titleData.hasParent === true &&
					titleData.hasChildren === false &&
					titleData.row_id !== 0 && <div style={{ marginLeft: '30px' }}></div>}
				<div onClick={handleCardClick} style={{ alignItems: 'center', display: 'contents' }}>
					<Tooltip
						title={`Entity Type: ${entityType} ${status ? `/ Status : ${status}` : ''}`}
						arrow
						placement="top"
					>
						<div style={{ alignItems: 'center', display: 'flex' }}>
							<TableIcon
								entityType={entityType}
								isBlueprint={titleData.isBlueprint}
								isTemplate={titleData.isTemplate}
								isArchived={isArchived}
								status={status}
							/>
						</div>
					</Tooltip>
					<Tooltip title={tooltipTitle} arrow placement="top">
						<StyledWrapper.CardRowTitle
							ref={cardRowTitleRef}
							display="inline"
							isClickable={!!handleCardClick}
						>
							{title}
						</StyledWrapper.CardRowTitle>
					</Tooltip>

					<StyledWrapper.CardRowId status={isArchived}>
						{entityType == 'Program' ? `[p-${displayId}]` : `[${displayId}]`}
					</StyledWrapper.CardRowId>
				</div>
			</StyledWrapper.CardRowContainer>
		</>
	);
};
