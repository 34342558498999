import StyledWrapper from './style';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';

interface CoreInfoWarningProps {
	isOpen: boolean;
	handleClose: () => void;
	handleStay: () => void;
}

export default function CoreInfoWarning({ isOpen, handleClose, handleStay }: CoreInfoWarningProps) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<StyledWrapper.CustomDialog
			disableEscapeKeyDown
			fullScreen={fullScreen}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
				<WarningIcon color={'warning'} />
				Warning!
			</StyledWrapper.CustomDialogTitle>
			<StyledWrapper.CustomDialogContent>
				<DialogContentText sx={{ fontWeight: '500' }}>
					{`The system will automatically create a Mandatory Core Information form. Since the template already contains an application form which may include duplicated fields already found in the Core Information form, we advise reviewing the forms in order to make sure that they are correct!`}
					<br />
					{`Are you sure?`}
				</DialogContentText>
			</StyledWrapper.CustomDialogContent>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button size="large" variant="outlined" color="warning" onClick={handleClose} autoFocus>
					Close
				</Button>
				<Button size="large" variant="contained" autoFocus onClick={handleStay}>
					Yes - Use Template
				</Button>
			</DialogActions>
		</StyledWrapper.CustomDialog>
	);
}
