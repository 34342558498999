import { CSSProperties } from 'react';
import StyledWrapper from './style';

interface ItemPageTitleProps {
	text?: string;
	removeGrayLine?: boolean;
	isUnsaved?: boolean;
	style?: CSSProperties;
}

export default function ItemPageTitle({
	text,
	removeGrayLine,
	isUnsaved,
	style
}: ItemPageTitleProps) {
	return (
		<StyledWrapper.Container style={style}>
			{text ? (
				<>
					{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}
					<StyledWrapper.TitleText data-testid={'itemPageTitle-text'}>
						{text}
					</StyledWrapper.TitleText>
					{isUnsaved && ' / '}
					{isUnsaved && <StyledWrapper.UnsavedText>unsaved</StyledWrapper.UnsavedText>}
					{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}
				</>
			) : (
				<>{!removeGrayLine && <StyledWrapper.GrayLine data-testid={'itemPageTitle-grayline'} />}</>
			)}
		</StyledWrapper.Container>
	);
}
