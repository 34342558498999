import { useReducer, useCallback } from 'react';

interface AlertState {
	error: { isOpen: boolean; message: string };
	warning: { isOpen: boolean; message: string };
	success: { isOpen: boolean; message: string };
}

interface OpenAlertPayload {
	message: string | string[];
	type: 'error' | 'warning' | 'success';
}

type AlertAction =
	| { type: 'OPEN_ALERT'; payload: OpenAlertPayload }
	| { type: 'CLOSE_ALERT'; payload: { type: 'error' | 'warning' | 'success' } }
	| { type: 'CLEAR_ALL' };

const initialAlertState: AlertState = {
	error: { isOpen: false, message: '' },
	warning: { isOpen: false, message: '' },
	success: { isOpen: false, message: '' }
};

export interface AlertManager {
	alertState: AlertState;
	openAlert: (message: string | string[], type: 'error' | 'warning' | 'success') => void;
	closeAlert: (type: 'error' | 'warning' | 'success') => void;
	clearAllAlerts: () => void;
}

function alertReducer(state: AlertState, action: AlertAction): AlertState {
	switch (action.type) {
		case 'OPEN_ALERT':
			return {
				...state,
				[action.payload.type]: {
					isOpen: true,
					message: action.payload.message
				}
			};
		case 'CLOSE_ALERT':
			return {
				...state,
				[action.payload.type]: {
					isOpen: false,
					message: ''
				}
			};
		case 'CLEAR_ALL':
			return initialAlertState;
		default:
			return state;
	}
}

export function useCopyAlertBalloons(): AlertManager {
	const [alertState, dispatch] = useReducer(alertReducer, initialAlertState);

	const openAlert = useCallback(
		(message: string | string[], type: 'error' | 'warning' | 'success') => {
			dispatch({ type: 'OPEN_ALERT', payload: { message, type } });
		},
		[]
	);

	const closeAlert = useCallback((type: 'error' | 'warning' | 'success') => {
		dispatch({ type: 'CLOSE_ALERT', payload: { type } });
	}, []);

	const clearAllAlerts = useCallback(() => {
		dispatch({ type: 'CLEAR_ALL' });
	}, []);

	return { alertState, openAlert, closeAlert, clearAllAlerts };
}
