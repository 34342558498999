import StyledWrapper from '../../../pages/IndividualProgram/style';

import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import type { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { CopyProcessTableRow } from '../types';

export const getProcessColumns = (): GridColumns<CopyProcessTableRow> => {
	const columns = [
		{
			field: 'department',
			headerName: 'Department',
			description: 'Department',
			renderCell: (cellValues) => {
				return <>{cellValues?.formattedValue}</>;
			},
			flex: 1,
			sortable: false,
			disableColumnMenu: true
		},
		{
			field: 'app_process_is_ready_to_publish',
			headerName: 'App Setup Status',
			description: 'App Setup Status',
			renderCell: (cellValues) => {
				return (
					<>
						<Tooltip
							arrow
							placement="top"
							title={
								cellValues?.row?.app_process_is_ready_to_publish === true
									? 'Setup Finished'
									: 'Needs Setup'
							}
						>
							<StyledWrapper.CardRowInfo>
								{cellValues?.row?.app_process_is_ready_to_publish === true ? (
									<CheckIcon color="success" />
								) : (
									<WarningIcon color="warning" />
								)}
							</StyledWrapper.CardRowInfo>
						</Tooltip>
					</>
				);
			},
			flex: 1,
			align: 'center',
			minWidth: 150,
			maxWidth: 150,
			sortable: false,
			disableColumnMenu: true
		}
	] as GridColumns<CopyProcessTableRow>;

	return columns;
};
