import { MouseEventHandler, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import type { GridRowId, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import type { CopyFromProcessDialogProps, CopyProcessResponse, CopyProcessTableRow } from './types';

import Styled from './style';
import { Table } from '../Table';
import { ItemPageTitle } from '../ItemPageTitle';
import { CopyProcessIcon } from '../../assets/customIcons/CopyProcessIcon';

import usePaginatedCall from '../../hooks/usePaginatedCall';
import {
	useCopyApplicationProcessMutation,
	useGetAllProgramsQuery
} from '../../services/endpoints/programsEndpoints';
import { formatApiCall } from './utils/utils';
import { getLocalAuth } from '../../utils/environmentUtils';
import { groupingColDef } from './utils/groupingColDef';
import { getProcessColumns } from './utils/getProcessColumns';

export default function CopyFromProcessDialog({
	isOpen,
	handleClose,
	alertManager
}: CopyFromProcessDialogProps) {
	const accountSource = useAppSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const [selectedRow, setSelectedRow] = useState<GridValueFormatterParams<CopyProcessTableRow>>();
	const searchInputRef = useRef<HTMLInputElement>();
	const [fullTextSearch, setFullTextSearch] = useState('');

	const stateRef = useRef<GridRowId>();
	stateRef.current = selectedRow?.id as GridRowId;

	const processColumns = getProcessColumns();
	const getTreeDataPath = (row: CopyProcessTableRow) => row?.hierarchy;

	const pagination = usePaginatedCall(
		useGetAllProgramsQuery,
		// @ts-expect-error: usePaginatedCall is not typed properly at the moment.
		{
			overallFilter: 'active',
			filterBaseName: 'CHOOSE_PROCESS',
			queryParams: {
				fullTextSearch,
				programsToExclude: `${[programID].join(',')}`
			}
		}
	);

	const [copyProcess, { isLoading: isApCopying }] = useCopyApplicationProcessMutation();

	const handleSearchClick: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		setFullTextSearch(searchInputRef?.current?.value || '');
	};

	const handleProcessCopying = () => {
		const endpointOptions = {
			apiToken: apiToken,
			payload: {
				original_program_id: selectedRow?.id,
				program_id: programID
			}
		};

		copyProcess(endpointOptions)
			.unwrap()
			.then(async (response: CopyProcessResponse) => {
				if (response?.process_id) {
					alertManager.clearAllAlerts();
					alertManager.openAlert(
						[
							`The "Application Process for Program: ${response?.original_program_name}" [${response?.applied_process?.unique_identifier}] Application Process Master was successfully copied with the new name "Application Process for Program: ${response?.process_name}" [${response?.unique_identifier}] into the present program. `,
							'Now you can edit the titles and any settings you need for each entity.'
						],
						'success'
					);
					alertManager.openAlert(
						'Dates from the originally copied program have been cleared. The Application Process start date has been set to today and the end date has been set to 90 days from today. Please be sure to modify the Application Process date range and adjust any Submission Stage and Evaluation Stage dates as required.',
						'warning'
					);
				} else {
					alertManager.openAlert(
						"The copy process didn't finalize due to a system error. Please try again or contact support.",
						'error'
					);
				}
			})
			.catch(() => {
				alertManager.openAlert(
					"The copy process didn't finalize due to a system error. Please try again or contact support.",
					'error'
				);
			})
			.finally(() => {
				handleClose();
			});
	};

	const handleTitleClick = (link: string) => {
		window.open(link, '_blank');
	};

	return (
		<>
			<Styled.Dialog
				fullWidth
				maxWidth="md"
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<Styled.DialogContent>
					{pagination?.isLoading ? (
						<Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
							<CircularProgress />
						</Box>
					) : (
						<>
							<Styled.Head>
								<Styled.DialogTitle id="responsive-dialog-title">
									<CopyProcessIcon />
									Copy an actual Application Process Master
								</Styled.DialogTitle>
								<Styled.DialogContentText>
									{'Pick an actual Application Process from the list of programs below to be copied into your program. ' +
										'The entire structure will be copied (stages, forms, etc.). Once it is created, you can edit and customize it as needed.'}
								</Styled.DialogContentText>
							</Styled.Head>
							<ItemPageTitle text="Programs List" />
							<Styled.InputContainer>
								<Styled.SearchInput
									fullWidth
									id="fullTextProgramsSearch-input-id"
									placeholder="Type Program or Department Name..."
									inputRef={searchInputRef}
									size="small"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
								/>
								<Button size="large" variant="outlined" onClick={handleSearchClick} autoFocus>
									SEARCH
								</Button>
							</Styled.InputContainer>
							<Table
								marginTop={0}
								columns={processColumns}
								rowHeight={52}
								enableAutoPageSize
								rows={formatApiCall(pagination?.rows?.results || [])}
								getRowId={(row: CopyProcessTableRow) => row.id}
								rowCount={pagination?.rowCount}
								pagination={pagination?.pagination}
								pageSize={pagination?.pageSize}
								paginationMode={pagination?.paginationMode}
								onPageChange={(e: unknown) => pagination?.onPageChange(e)}
								onPageSizeChange={(e: unknown) => pagination?.onPageSizeChange(e)}
								page={pagination?.page}
								loading={pagination?.loading}
								sortingMode={pagination?.sortingMode}
								onSortModelChange={(e: unknown) => pagination?.onSortModelChange(e)}
								hideFilters
								hideTableActions
								disableSelectionOnClick
								treeData
								getTreeDataPath={getTreeDataPath}
								groupingColDef={() =>
									groupingColDef({
										handleRowSelection: setSelectedRow,
										stateRef,
										handleTitleClick
									})
								}
								filterModel={pagination?.filterModel}
								sortModel={pagination?.sortModel}
							/>
							<DialogActions style={{ justifyContent: 'center' }}>
								<Button size="large" variant="outlined" onClick={handleClose} autoFocus>
									Cancel
								</Button>
								<Tooltip
									arrow
									placement="top"
									title={!selectedRow ? 'Select an option first in order to copy.' : ''}
								>
									<div>
										<LoadingButton
											size="large"
											variant="contained"
											autoFocus
											onClick={handleProcessCopying}
											disabled={!selectedRow}
											loading={isApCopying}
										>
											COPY
										</LoadingButton>
									</div>
								</Tooltip>
							</DialogActions>
						</>
					)}
				</Styled.DialogContent>
			</Styled.Dialog>
		</>
	);
}
