const getRadioOrCheckboxValues = (currentValues) => {
	let allCheckedValues = [];
	currentValues?.forEach((e) => {
		const name = `option_${e}`;
		const input = document.querySelector(`input[name=${name}]`);
		input?.checked && allCheckedValues.push(input.getAttribute('data-value'));
	});
	return allCheckedValues;
};

//CHECK TO SEE IF INPUT EXISTS - HAS ANY VALUE
export const inputExists = (currentValue) => {
	return (currentValue !== '' && currentValue !== undefined) || currentValue?.length > 0;
};

// CHECK TO SEE IF INPUT DOES NOT EXIST - HAS NO VALUE
export const inputNotExists = (currentValue) => {
	return currentValue === '' || currentValue === undefined || currentValue?.length === 0;
};

// CHECK TO SEE IF INPUT IS CERTAIN VALUE
export const inputIs = (currentValue, dependancyValue) => {
	if (currentValue?.includes('option_') && !dependancyValue?.value?.includes('option_')) {
		const actualValue = document.querySelector(`input[name=${dependancyValue?.field}]`);
		const finalValue = actualValue?.getAttribute('actualvalue');

		return finalValue == dependancyValue?.value;
	}

	if (Array.isArray(currentValue)) {
		if (!dependancyValue?.value?.includes('option_')) {
			const actualValues = getRadioOrCheckboxValues(currentValue);
			return actualValues?.includes(dependancyValue?.value);
		} else {
			return currentValue?.includes(dependancyValue?.value);
		}
	} else {
		return currentValue == dependancyValue?.value;
	}
};

// CHECK TO SEE IF INPUT IS NOT A CERTAIN VALUE
export const inputIsNot = (currentValue, dependancyValue) => {
	if (currentValue?.includes('option_') && !dependancyValue?.value?.includes('option_')) {
		const actualValue = document.querySelector(`input[name=${dependancyValue?.field}]`);
		const finalValue = actualValue?.getAttribute('actualvalue');

		return finalValue !== dependancyValue?.value;
	}

	if (Array.isArray(currentValue)) {
		if (!dependancyValue?.value?.includes('option_')) {
			const actualValues = getRadioOrCheckboxValues(currentValue);
			return !actualValues?.includes(dependancyValue?.value);
		} else {
			return !currentValue?.includes(dependancyValue?.value);
		}
	} else {
		return currentValue !== dependancyValue?.value;
	}
};

// CHECK TO SEE IF INPUT CONTAINS A CERTAIN VALUE
export const inputContains = (currentValue, dependancyValue) => {
	currentValue = currentValue?.toString();
	dependancyValue = dependancyValue?.value?.toString() || dependancyValue?.toString();

	if (!currentValue && dependancyValue) return false;

	if (Array.isArray(currentValue)) {
		if (!dependancyValue?.value?.includes('option_')) {
			const actualValues = getRadioOrCheckboxValues(currentValue);
			return actualValues?.includes(dependancyValue?.value);
		} else {
			return currentValue?.includes(dependancyValue?.value);
		}
	} else {
		return currentValue?.toLowerCase()?.includes(dependancyValue?.toLowerCase());
	}
};

// CHECK TO SEE IF INPUT DOES NOT CONTAIN A CERTAIN VALUE
export const inputNotContains = (currentValue, dependancyValue) => {
	currentValue = currentValue?.toString();
	dependancyValue = dependancyValue?.value?.toString() || dependancyValue?.toString();

	if (Array.isArray(currentValue)) {
		if (!dependancyValue?.value?.includes('option_')) {
			const actualValues = getRadioOrCheckboxValues(currentValue);
			return !actualValues?.includes(dependancyValue?.value);
		} else {
			return !currentValue?.includes(dependancyValue?.value);
		}
	} else {
		return !currentValue?.toLowerCase()?.includes(dependancyValue?.toLowerCase());
	}
};

// CHECK TO SEE IF INPUT IS LESS THAN OR EQUAL TO A CERTAIN VALUE
export const isMax = (currentValue, dependancyValue) => {
	if (currentValue?.includes('option_') && !dependancyValue?.value?.includes('option_')) {
		const actualValue = document.querySelector(`input[name=${dependancyValue?.field}]`);
		const finalValue = actualValue?.getAttribute('actualvalue');
		const currentNumber = parseFloat(finalValue);
		const currentDependencyNumber = parseFloat(dependancyValue?.value);
		return currentNumber <= currentDependencyNumber;
	} else {
		const currentNumber = parseFloat(currentValue);
		const currentDependencyNumber = parseFloat(dependancyValue?.value);
		return currentNumber <= currentDependencyNumber;
	}
};

// CHECK TO SEE IF INPUT IS GREATER THAN OR EQUAL TO A CERTAIN VALUE
export const isMin = (currentValue, dependancyValue) => {
	if (currentValue?.includes('option_') && !dependancyValue?.value?.includes('option_')) {
		const actualValue = document.querySelector(`input[name=${dependancyValue?.field}]`);
		const finalValue = actualValue?.getAttribute('actualvalue');
		const currentNumber = parseFloat(finalValue);
		const currentDependencyNumber = parseFloat(dependancyValue?.value);
		return currentNumber >= currentDependencyNumber;
	} else {
		const currentNumber = parseFloat(currentValue);
		const currentDependencyNumber = parseFloat(dependancyValue?.value);
		return currentNumber >= currentDependencyNumber;
	}
};

// CHECK TO SEE IF INPUT STARTS WITH A CERTAIN VALUE
export const startsWith = (currentValue, dependancyValue) => {
	if (!currentValue) {
		return false;
	}
	return (
		currentValue && currentValue?.toLowerCase()?.startsWith(dependancyValue?.value?.toLowerCase())
	);
};

// CHECK TO SEE IF INPUT DOES NOT STARTS WITH A CERTAIN VALUE
export const endsWith = (currentValue, dependancyValue) => {
	if (!currentValue) {
		return false;
	}
	return (
		currentValue && currentValue?.toLowerCase()?.endsWith(dependancyValue?.value?.toLowerCase())
	);
};

export const checkAllConditions = (dependancies, answers) => {
	let allAnswers = [];
	answers?.forEach((e) => {
		e !== undefined && e !== null && allAnswers.push(...e);
	});

	dependancies?.forEach((dependency) => {
		const dependantInputs = dependency?.dependency;
		let resultsArray = [];

		let search_name = dependency?.field_name;
		if (search_name && search_name.includes('linkInput_')) {
			// linkInput has an unmatched name
			search_name = search_name + '-title';
		}

		const element = document.getElementsByName(search_name);
		const sortableItemParent = element[0]?.closest('.SortableItem');

		dependantInputs?.forEach((dependantInput) => {
			const input = document.querySelector(`input[name=${dependantInput?.field}]`);
			if (input?.classList?.value?.includes('hidden-dependancy')) {
				resultsArray.push(false);
				return;
			}

			if (Array.isArray(dependantInput)) {
				let innerResults = [];

				dependantInput?.forEach((innerInput) => {
					const currentValue = allAnswers?.find((e) => e?.name === innerInput?.field);
					const currentResult = triggerConditionCheck(
						innerInput?.condition,
						currentValue?.value,
						innerInput
					);
					innerResults.push(currentResult);
				});

				innerResults.some((item) => item === true)
					? resultsArray?.push(true)
					: resultsArray?.push(false);
			} else {
				const currentValue = allAnswers?.find((e) => e?.name === dependantInput?.field);
				const currentResult = triggerConditionCheck(
					dependantInput?.condition,
					currentValue?.value,
					dependantInput
				);
				resultsArray.push(currentResult);
			}
		});

		if (resultsArray.some((item) => item === false)) {
			element.length >= 1 && sortableItemParent.classList.add('hidden-dependancy');
		} else if (element.length >= 1 && element[0]) {
			element.length >= 1 && sortableItemParent.classList.remove('hidden-dependancy');
		}
	});
};

export const checkConditions = (/* changedInput, dependancies */) => {
	// const hasOptions = ['radio', 'checkbox'].indexOf(changedInput?.type) > -1;
	// const currentValue = hasOptions
	// 	? [changedInput?.id?.replace('fid_preview_', '')]
	// 	: changedInput?.value;
	// const currentFieldName = changedInput?.name;
	// let isDependant = dependancies?.filter((item) => item?.dependency?.field === currentFieldName);
	// isDependant?.forEach((dependency) => {
	// 	triggerConditionCheck(dependency?.dependency?.condition, dependency, currentValue);
	// });
};

const triggerConditionCheck = (condition, currentValue, dependency) => {
	switch (condition) {
		case 'does not exist':
			return inputNotExists(currentValue, dependency);
		case 'exists':
			return inputExists(currentValue, dependency);
		case 'is':
			return inputIs(currentValue, dependency);
		case '':
			return inputIs(currentValue, dependency);
		case 'not':
			return inputIsNot(currentValue, dependency);
		case 'in':
			return inputContains(currentValue, dependency);
		case 'contains':
			return inputContains(currentValue, dependency);
		case 'not-in':
			return inputNotContains(currentValue, dependency);
		case 'not-contains':
			return inputNotContains(currentValue, dependency);
		case 'min':
			return isMin(currentValue, dependency);
		case 'max':
			return isMax(currentValue, dependency);
		case 'starts-with':
			return startsWith(currentValue, dependency);
		case 'ends-with':
			return endsWith(currentValue, dependency);
		default:
			return 'default value';
	}
};
