import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StyledWrapper from './style';

import { capitalizeFirstLetter } from '../FormBuilder/utils';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
	FORM_DELETION_WARNING_TITLE,
	FORM_DELETION_WARNING_MESSAGE
} from '../../constants/appProcessEditWarningMessage';
import {
	useDeleteFormMutation,
	useDeleteStageMutation
} from '../../services/endpoints/stagesEndpoints';
import { useDeleteApplicationProcessMutation } from '../../services/endpoints/programsEndpoints';
import { useUpdateCoreInfoStageMutation } from '../../services/endpoints/formBuilderEndpoints';
import { getLocalAuth } from '../../utils/environmentUtils';

export default function DeleteDialog({
	refetch,
	title,
	appProcessData,
	handleAlert,
	props,
	alertManager
}) {
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState();
	const [type, setType] = useState();
	const [hasBlock, setHasBlock] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [deleteForm] = useDeleteFormMutation();
	const [deleteStage] = useDeleteStageMutation();
	const [deleteAppProcess] = useDeleteApplicationProcessMutation();
	const [updateCoreInfoStage] = useUpdateCoreInfoStageMutation();

	const coreInfoID = appProcessData?.find(
		(e) => e?.type === 'Submission Stage' && e?.hasCoreInfo
	)?.coreFormID;
	const nextSubmissionStage = appProcessData?.find(
		(e) => e?.type === 'Submission Stage' && !e?.hasCoreInfo
	);
	const isReadyToPublish = appProcessData && appProcessData[0]?.in_use;
	const handleClose = () => {
		setOpen(false);
		searchParams.delete('deleteModal');
		searchParams.delete('type');
		setSearchParams(searchParams);
		setDeleteStatus(false);
	};

	const handleDelete = () => {
		let deleteFormOptions = {
			id: searchParams.get('deleteModal'),
			apiToken: apiToken
		};

		if (searchParams.get('type') === 'process') {
			deleteAppProcess(deleteFormOptions)
				.unwrap()
				.then(async () => {
					setDeleteStatus('Success');
				})
				.catch(() => {
					setDeleteStatus('Error');
				});
		} else if (searchParams.get('hasCoreInfo') === 'true' && nextSubmissionStage) {
			const updateFormOptions = {
				form_id: coreInfoID,
				apiToken: apiToken,
				stage_id: nextSubmissionStage?.appId
			};

			updateCoreInfoStage(updateFormOptions)
				.unwrap()
				.then(async () => {
					deleteStage(deleteFormOptions)
						.unwrap()
						.then(async () => {
							setDeleteStatus('Success');
						})
						.catch(() => {
							setDeleteStatus('Error');
						});
				})
				.catch(() => {
					setDeleteStatus('Error');
				});
		} else if (searchParams.get('type') === 'stage') {
			deleteStage(deleteFormOptions)
				.unwrap()
				.then(async () => {
					setDeleteStatus('Success');
				})
				.catch(() => {
					setDeleteStatus('Error');
				});
		} else {
			deleteForm(deleteFormOptions)
				.unwrap()
				.then(async () => {
					setDeleteStatus('Success');
				})
				.catch(() => {
					setDeleteStatus('Error');
				});
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (deleteStatus) {
			const appProcessItem = appProcessData?.find(
				(e) => e?.appId === searchParams.get('deleteModal')
			);
			const applicationType = appProcessItem ? appProcessItem.type : undefined;
			if (applicationType) {
				const message =
					deleteStatus == 'Success'
						? `${capitalizeFirstLetter(applicationType)} ${
								appProcessData.template === true ? 'template' : ''
						  } was successfully deleted.`
						: 'Deletion Failed!';
				handleAlert(message, deleteStatus?.toLowerCase());
				alertManager?.clearAllAlerts();
			} else {
				const message =
					deleteStatus == 'Success'
						? `${props.type} template was successfully deleted.`
						: 'Deletion Failed!';
				handleAlert(message, deleteStatus?.toLowerCase());
			}
			refetch();
			handleClose();
		}
	}, [deleteStatus]);

	useEffect(() => {
		const dialogState = searchParams.get('deleteModal');
		const typeParam = searchParams.get('type');

		dialogState ? setOpen(true) : setOpen(false);
		typeParam && setType(type);

		let appProcessItem = false;
		let hasBlock = false;
		appProcessData?.every((e) => {
			if (appProcessItem?.type === 'Submission Stage' && e?.type === 'Submission Stage') {
				return false;
			}

			if (e?.appId === searchParams.get('deleteModal')) {
				appProcessItem = e;
			}

			if (appProcessItem?.type === 'Submission Stage' && e?.type === 'Evaluation Stage') {
				hasBlock = true;
			}
			return true;
		});

		setHasBlock(hasBlock);
	}, [searchParams]);

	return (
		<div>
			<StyledWrapper.CustomDialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				{isLoading ? (
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						<StyledWrapper.CustomDialogTitle id="responsive-dialog-title">
							<WarningIcon color={'warning'} />
							{title}
							<DialogContentText>{`This action cannot be undone.`}</DialogContentText>
						</StyledWrapper.CustomDialogTitle>

						<StyledWrapper.CustomDialogContent>
							{searchParams.get('type') === 'stage' && (
								<DialogContentText>
									{hasBlock ? ' All stages in the block will be deleted.' : ''}
								</DialogContentText>
							)}
							{isReadyToPublish && (
								<DialogContentText>
									{FORM_DELETION_WARNING_TITLE}
									<ul>
										<li>{FORM_DELETION_WARNING_MESSAGE}</li>
									</ul>
								</DialogContentText>
							)}
							<DialogContentText
								sx={{ fontWeight: '500' }}
							>{`Are you sure you want to proceed?`}</DialogContentText>
						</StyledWrapper.CustomDialogContent>
						<DialogActions sx={{ justifyContent: 'center' }}>
							<Button size="large" variant="outlined" autoFocus onClick={handleClose}>
								NO - CLOSE THE WINDOW
							</Button>
							<Button
								size="large"
								variant="contained"
								color="warning"
								onClick={() => {
									setIsLoading(true);
									handleDelete();
								}}
								autoFocus
							>
								YES - DELETE THE RECORD
							</Button>
						</DialogActions>
					</>
				)}
			</StyledWrapper.CustomDialog>
		</div>
	);
}
