import { SvgIcon } from '@mui/material';

export const CopyProcessIcon = () => (
	<SvgIcon>
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5 20.9317C16.2614 20.9317 20.9319 16.2612 20.9319 10.4998C20.9319 4.7384 16.2614 0.0678711 10.5 0.0678711C4.73864 0.0678711 0.0681152 4.7384 0.0681152 10.4998C0.0681152 16.2612 4.73864 20.9317 10.5 20.9317ZM9.75859 3.27671C9.55658 3.0747 9.28547 2.96307 9.00372 2.96307H5.19216C4.60741 2.96307 4.12897 3.4415 4.12897 4.02626V12.5318C4.12897 13.1166 4.60209 13.595 5.18685 13.595H5.47195V5.36925C5.47195 4.78449 5.95039 4.30605 6.53515 4.30605H10.3467H11.0549L9.75859 3.27671ZM11.0182 4.97754C11.2999 4.97754 11.5711 5.08918 11.7731 5.29118L13.0693 6.32053H12.3612H8.54962C7.96486 6.32053 7.48643 6.79896 7.48643 7.38372V15.6095H7.20132C6.61657 15.6095 6.14344 15.1311 6.14344 14.5463V6.04074C6.14344 5.45598 6.62188 4.97754 7.20664 4.97754H11.0182ZM13.7875 7.30566C13.5855 7.10365 13.3144 6.99202 13.0327 6.99202H9.22111C8.63636 6.99202 8.15792 7.47046 8.15792 8.05521V16.5608C8.15792 17.1455 8.63104 17.624 9.2158 17.624H15.6003C16.185 17.624 16.6635 17.1455 16.6635 16.5608V10.6228C16.6635 10.3411 16.5518 10.07 16.3498 9.87328L13.7875 7.30566ZM14.0055 15.4976H10.8159C10.5235 15.4976 10.2843 15.2584 10.2843 14.966C10.2843 14.6736 10.5235 14.4344 10.8159 14.4344H14.0055C14.2979 14.4344 14.5371 14.6736 14.5371 14.966C14.5371 15.2584 14.2979 15.4976 14.0055 15.4976ZM14.0055 13.3712H10.8159C10.5235 13.3712 10.2843 13.132 10.2843 12.8396C10.2843 12.5472 10.5235 12.308 10.8159 12.308H14.0055C14.2979 12.308 14.5371 12.5472 14.5371 12.8396C14.5371 13.132 14.2979 13.3712 14.0055 13.3712ZM12.9423 10.1816V7.78941L15.8661 10.7132H13.4739C13.1815 10.7132 12.9423 10.474 12.9423 10.1816Z"
				fill="black"
				fillOpacity="0.6"
			/>
		</svg>
	</SvgIcon>
);
