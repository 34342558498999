import styled from 'styled-components';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import MuiDialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

const Dialog = styled(MuiDialog)`
	width: 100%;

	& .MuiDialog-paper {
		padding: 30px;
	}
`;

const DialogTitle = styled(MuiDialogTitle)`
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 15px;
	padding-left: 0;
	padding-right: 0;
`;

const DialogContent = styled(MuiDialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	& .MuiDataGrid-treeDataGroupingCellToggle {
		display: none !important;
	}
`;

const InputContainer = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 8px;
`;

const Head = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: start;
	flex-direction: column;
`;

const DialogContentText = styled(MuiDialogContentText)`
	font-weight: 500;
	text-align: start;
`;

const SearchInput = styled(TextField)`
	& .MuiOutlinedInput-root {
		background-color: rgb(236, 239, 241);
	}
`;

export default {
	Head,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	InputContainer,
	SearchInput
};
